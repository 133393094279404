import { ensureSingleScript } from '@snapchat/mw-common/client';
import { CategoryOptInCookie } from '@snapchat/mw-cookie-components/src/components/types';
import { BrowserFeaturesContext } from '@snapchat/snap-design-system-marketing';
import { HlsAdapter } from 'bitmovin-analytics';
import { cx } from 'emotion';
import debounce from 'lodash-es/debounce';
import isUndefined from 'lodash-es/isUndefined';
import noop from 'lodash-es/noop';
import throttle from 'lodash-es/throttle';
import type { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { AppContext } from '../../../../AppContext';
import type { TimestampBehavior } from '../../../../components/EventPlayer/types';
import { Config } from '../../../../config';
import { logError, logInfo, logUserEvent, logValue } from '../../../../helpers/logging';
import type {
  AkamaiMediaConfiguration,
  OnCanPlayThroughHandler,
  OnFullScreenChangeHandler,
  OnLoadedMetadataHandler,
  OnMuteChangeHandler,
  OnPlayStateChangeHandler,
  OnSeekedHandler,
  OnSettingsChangeHandler,
  OnTimedMetadataHandler,
  OnTimeUpdateHandler,
  OnVolumeChangeHandler,
  Player,
} from '../../../../types/akamai';
import { UserAction } from '../../../../types/events';
import { isIpad } from '../../../../utils/userAgent/userAgentHints';
import {
  ampAslActiveCss,
  ampHideAslCss,
  ampHideCcCss,
  ampPlayerCss,
  aslButtonCss,
  firefoxVideoPlayerCss,
  videoPlayerShowControlsCss,
} from './AkamaiPlayer.styles';
import { akamaiScriptSrc, buildAkamaiPlayerConfig } from './constants';
import type { SharedControlsState } from './types';
import { volumeControlCss, volumeIconCss } from './VolumeControl.styles';

/**
 * License key associated with "default-license" of our Bitmovin account. Key can be found in
 * Bitmovin dashboard: https://dashboard.bitmovin.com/analytics/licenses (credentials are in Last
 * Pass under "Bitmovin Analytics")
 */
const bitmovinLicenseKey = 'f0137608-4f59-4afc-a666-93af4bb0f7dd';

/**
 * Selectors for the buttons we display in Akamai. These are based on the classnames from the Akamai
 * player. Ideally we would use classnames that we assign ourselves, but amp will overwrite those
 * whenever they update the classes.
 */
enum ControlButton {
  ASL = '.amp-asl',
  CAPTIONS = 'button.amp-settings',
  FULLSCREEN = '.amp-fullscreen',
  MUTE = '.amp-custom-mute',
  PAUSE_OVERLAY = '.amp-pause-overlay',
  PLAY = '.amp-playpause',
  VOLUME = '.amp-custom-volume-slider',
}

export type VideoControlsAction =
  | { type: 'changeHoverState'; value: boolean }
  | { type: 'changeFullScreenState'; value: boolean }
  | { type: 'changePlayingState'; value: boolean }
  | { type: 'changeLoadingState'; value: boolean }
  | { type: 'toggleAslSelectedState' };

/** Function that loads the Akamai script. */
function ensureAkamaiScript(onLoaded: () => void) {
  const scriptId = 'akamai-player-script';
  const source = akamaiScriptSrc;
  ensureSingleScript(scriptId, source, onLoaded);
}

export interface AkamaiPlayerProps {
  media: AkamaiMediaConfiguration;
  aslMedia?: AkamaiMediaConfiguration;
  player?: Player;
  isPlaying: boolean;
  isLiveEvent: boolean;
  showControls: SharedControlsState;
  /**
   * Defines how timestamps are reported...
   *
   * "Use Progress Timestamps" will report current second of video starting from 0. "Use Manifest
   * Timestamps" will pull and report UTC timestamp from stream manifest file. "Use Calculated
   * Timestamps" will derive and report UTC timestamp by adding given "offset" to current second of
   * video (starting from 0).
   */
  timestampBehavior: TimestampBehavior;
  /**
   * Amount to offset video progress by. Impacts how watch time is logged and how progress is
   * reported back to consumers via "onProgressUpdated"
   */
  progressOffset?: number;
  disableCaptions?: boolean;
  setPlayer?: Dispatch<SetStateAction<Player | undefined>>;
  controlsDispatch: Dispatch<VideoControlsAction>;
  onProgressUpdated?: (timestamp: number) => void;
  analyticsId: string;
  onReceiveBookmark?: (bookmarkId: string) => void;
}

export const AkamaiPlayer: FC<AkamaiPlayerProps> = ({
  media,
  aslMedia,
  player,
  isPlaying,
  isLiveEvent,
  showControls,
  timestampBehavior,
  progressOffset = 0,
  disableCaptions = false,
  setPlayer,
  controlsDispatch,
  onProgressUpdated,
  onReceiveBookmark,
  analyticsId,
}) => {
  const { cookieManager } = useContext(AppContext);
  /* === state management =========================================================== */

  const [isAkamaiScriptLoaded, setIsAkamaiScriptLoaded] = useState(false);
  const [videoContainerRef, setVideoContainerRef] = useState<HTMLDivElement>();
  const [isAslStreamPlaying, setIsAslStreamPlaying] = useState(false);
  const [inInitialLoadInterval, setInInitialLoadInterval] = useState(true);

  // used to gate logging user effects (set when video is in an active state);
  const shouldLogUserEvents = useRef(false);

  // Check if the browser is firefox
  const browserFeatures = useContext(BrowserFeaturesContext);
  const hints = browserFeatures.getLowEntropyHints();
  const isFirefox = hints.browsers.some(browser => browser.brand === 'Firefox');
  const isDesktop = !hints.isMobile;

  // Fires when the document node is mounted, used to defer useEffects till this occurs.
  const measuredContainerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node == null) return;
      setVideoContainerRef(node);
    },
    [setVideoContainerRef]
  );

  /** Used to update the volume bar display after player settings change */
  const refreshVolumeBarDisplayValue = useCallback(() => {
    if (!player) return;
    if (!videoContainerRef) return;

    const playerVolume = player.react.volume.value;
    const displayVolume = player.muted ? 0 : playerVolume;

    const volumeControlElement = videoContainerRef.getElementsByClassName(
      volumeControlCss
    )[0] as HTMLInputElement;

    if (!Config.isDeploymentTypeProd) {
      videoContainerRef.setAttribute('data-akamai-volume', `${playerVolume}`);
    }

    volumeControlElement.value = displayVolume.toString();
  }, [player, videoContainerRef]);

  /* === useEffects ================================================================= */

  /** Load Akamai Script onMount */
  useEffect(() => {
    // NOTE: Delayed because the elements aren't attached immediately after they are created.
    const timeout = setTimeout(() => {
      ensureAkamaiScript(() => {
        setIsAkamaiScriptLoaded(true);
      });
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [setIsAkamaiScriptLoaded]);

  /** Create Akamai Player once Akamai script is loaded */
  useEffect(() => {
    if (!isAkamaiScriptLoaded) return;

    // defer player creation till html elements are rendered
    if (!videoContainerRef) return;

    // TODO: add logic to assign unique playerIds if appropriate ENTWEB-7391
    const playerConfig = buildAkamaiPlayerConfig(media, undefined, disableCaptions);

    // behavior set by async function
    let cleanup = noop;

    window
      .akamai!.amp.AMP.create('amp', playerConfig)
      .then(ampPlayer => {
        ampPlayer.on<OnLoadedMetadataHandler>('loadedmetadata', () => {
          // When metadata is loaded, hls instance is available on amp

          // We can only run Bitmovin with cookies if user has opted into performance monitoring. Analytics without
          // cookies is still robust, but will not be able to track user between sessions, and the "Unique Users" metric
          // will be inaccurate.
          const acceptedPerformance =
            cookieManager.getCookieJson(CategoryOptInCookie.Essential) === true &&
            cookieManager.getCookieJson(CategoryOptInCookie.Performance) === true;

          const bitmovinAnalyticsConfig = {
            key: bitmovinLicenseKey,
            videoId: analyticsId,
            title: media.title,
            player: 'Akamai',
            isLive: isLiveEvent,
            config: {
              cookiesEnabled: acceptedPerformance,
            },
          };

          // HlsAdapted constructor will initiate analytics collection. We don't
          // need the returned adapter instance for our use cases.
          const _adapter = new HlsAdapter(bitmovinAnalyticsConfig, ampPlayer.hls.instance);
        });

        setPlayer?.(ampPlayer);

        // Akamai documentation recommends this over the `error` event
        ampPlayer.addTransform('error', function (error: unknown) {
          logError({
            component: 'AkamaiPlayer',
            error: error ?? 'Encountered Akamai error',
          });

          return error;
        });

        cleanup = () => {
          ampPlayer.destroy();
        };
      })
      .catch(error => logError({ component: 'AkamaiPlayer', error }));

    const timeout = setTimeout(() => setInInitialLoadInterval(false), 3_000);

    // remove player instance
    return () => {
      setPlayer?.(undefined);
      clearTimeout(timeout);
      cleanup();
    };
  }, [
    isAkamaiScriptLoaded,
    videoContainerRef,
    media,
    disableCaptions,
    analyticsId,
    isLiveEvent,
    setPlayer,
    cookieManager,
  ]);

  // Configure core event listeners
  useEffect(() => {
    if (!player) return;

    // When switching to ASL stream on mobile safari, the metadata track
    // gets disabled, thus preventing bookmarks from coming in. This
    // workaround will ensure that the metadata track gets re-enabled.
    player.on<OnCanPlayThroughHandler>('canplaythrough', () => {
      for (let i = 0; i < player.textTracks.length; i++) {
        if (player.textTracks[i]!.kind === 'metadata') {
          player.textTracks[i]!.mode = 'hidden';
        }
      }
    });

    player.on<OnPlayStateChangeHandler>('playstatechange', ({ detail }) => {
      const newState = detail.value;

      // update shared `isPlaying` and `isLoading` state
      controlsDispatch({ type: 'changePlayingState', value: newState === 'playing' });
      controlsDispatch({ type: 'changeLoadingState', value: newState === 'loading' });
    });

    if (isLiveEvent) {
      // force to stream to play from the live edge.
      player.on('resume', () => player.goLive());
    }

    player.on<OnFullScreenChangeHandler>('fullscreenchange', ({ detail: isFullscreen }) => {
      controlsDispatch({ type: 'changeFullScreenState', value: isFullscreen });
    });

    player.on<OnTimeUpdateHandler>('timeupdate', ({ detail }) => {
      if (timestampBehavior === 'Use Manifest Timestamps') {
        onProgressUpdated?.(player.toUTC(detail));
      } else if (timestampBehavior === 'Use Calculated Timestamps') {
        onProgressUpdated?.((detail + progressOffset) * 1000);
      } else {
        // timestampBehavior === 'Use Progress Timestamps'
        onProgressUpdated?.(detail * 1000);
      }
    });

    if (onReceiveBookmark) {
      player.on<OnTimedMetadataHandler>('timedmetadata', ({ detail }) => {
        if (typeof detail.value.data !== 'string') {
          return;
        }

        const metadataValue = detail.value.data;
        const [_, contentType, bookmark] = metadataValue.split(':');

        if (contentType === 'bookmarkV2' && bookmark) {
          onReceiveBookmark(bookmark);
        }

        if (contentType !== 'bookmarkV2') {
          logError({
            component: 'AkamaiPlayer',
            message: `Received content type ${contentType} instead of content type bookmarkV2.`,
          });
        }
      });
    }
  }, [
    player,
    isLiveEvent,
    onProgressUpdated,
    progressOffset,
    timestampBehavior,
    controlsDispatch,
    onReceiveBookmark,
  ]);

  // Configure custom Player UI
  useEffect(() => {
    if (!player) return;

    if (!videoContainerRef) return;

    if (aslMedia) {
      // Must use amp version of react
      const aslControlButton = window.React.createElement('button', {
        'data-testid': 'akamai-asl-control',
        className: cx('amp-icon', 'amp-asl', 'amp-control', 'amp-component', aslButtonCss),
        id: 'component',
        key: 'asl',
        'data-rh': 'ASL',
        onClick: function () {
          controlsDispatch({ type: 'toggleAslSelectedState' });
        },
      });

      player.react.controls.addComponent(aslControlButton);
    }
    // TODO: add logic to remove button if aslMedia prop changes to undefined.

    // Must use amp version of react
    const volumeBar = window.React.createElement('input', {
      'data-testid': 'akamai-volume-control',
      className: cx('amp-custom-volume-slider', volumeControlCss),
      type: 'range',
      min: 0,
      max: 100,
      step: 1,
      value: player.react.volume.value,
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        const volume = event.target.valueAsNumber;

        // this is a noop
        if (player.react.volume.value === volume) return;

        // otherwise update
        player.react.volume.value = volume;
      },
    });
    // NOTE: fired when either player volume or muted value are changed
    player.on('volumechange', refreshVolumeBarDisplayValue);

    // Must use amp version of react
    // This is a TRANSPARENT button that we overlay over the existing volume
    // icon, and we hijacks the clicks to it to also mute/unmute with an
    // additional benefit of triggering the volume slider on hover on this button.
    // TODO: This should be the existing volume button. I don't know why it's not used.
    const transparentVolumeButtonOverlay = window.React.createElement('div', {
      'data-testid': 'akamai-mute-control',
      className: cx(volumeIconCss, 'amp-custom-mute'),
      onClick: () => {
        if (player.muted) {
          player.unmute();
        } else {
          player.mute();
        }
      },
    });

    // Must use amp version of react
    const volumeControl = window.React.createElement('div', {
      className: cx('amp-control', 'amp-mute', 'amp-component', 'amp-icon', 'amp-custom-volume'),
      id: 'custom-volume',
      key: 'custom-volume',
      children: [volumeBar, transparentVolumeButtonOverlay],
    });

    // Add volume control
    player.react.controls.addComponent(volumeControl);

    const captionsButton = player.react.controlsNode.current.settings.current;

    // Store captions click to call it later
    const originalCaptionsClick = captionsButton.onClick;

    // Update closed captions hint tooltip
    captionsButton.elementNode.current.dataset.rh = 'Closed Captions';

    // Hijack settings button to show subtitles directly
    captionsButton.onClick = debounce(() => {
      // open settings panel
      originalCaptionsClick();

      const captionsButton = videoContainerRef.querySelector<HTMLElement>('.amp-subtitles');

      if (!captionsButton) {
        // wait for the captions button element to be created
        setTimeout(() => {
          // open custom captions panel
          videoContainerRef.querySelector<HTMLElement>('.amp-subtitles')?.click();
        }, 10);
      } else {
        // open custom captions panel
        captionsButton.click();
      }
    }, 50);

    // Add CC menu close button
    const onCloseHandler = () => {
      player.react.settingsPanelNode.current.open = false;
    };

    const settingsCloseButton = (
      <button tabIndex={0} className="close-button" onClick={onCloseHandler}>
        Close
      </button>
    );

    const captionSettingsClosePanel = document.createElement('div');
    captionSettingsClosePanel.classList.add('settings-close');

    player.react.settingsPanelNode.current.elementNode.current.appendChild(
      captionSettingsClosePanel
    );
    const closeButtonRoot = document.querySelector('.settings-close');
    closeButtonRoot && createRoot(closeButtonRoot).render(settingsCloseButton);

    if (isLiveEvent) {
      player.on<OnPlayStateChangeHandler>('playstatechange', ({ detail }) => {
        // For live events, we will override the "Pause" tooltip to "Stop"
        if (detail.value === 'playing') {
          const stopButton = document.querySelector('button.amp-playpause');
          stopButton?.setAttribute('data-rh', 'Stop');
          stopButton?.setAttribute('aria-label', 'Stop');
        }
      });
    }

    /*
     * Hack for the full screen button not working for Firefox on iPad. Amp player code expects
     * document.exitFullscreen or document.cancelFullscreen to exist when ampEl?.requestFullscreen
     * exists. However that's not true for Firefox on iPad. Since we know the exact code path that
     * leads to this error, patch it here.
     *
     * This workaround creates issues for the player internally - fullscreenchange events will not be fired and
     * the internal state that for tracking fullscreen mode will be wrong. So we should limit it to cases where we know
     * there is an issue.
     */

    // Query for the element directly instead of using the element node from amp player to bypass amp bugs
    const ampEl = document.querySelector('#amp');

    const hasUnexpectedFullscreenApi =
      ampEl?.requestFullscreen &&
      !document.exitFullscreen &&
      // @ts-ignore Check document.cancelFullscreen since that's what amp script also checks for
      !document.cancelFullscreen;

    if (isIpad(browserFeatures) && hasUnexpectedFullscreenApi) {
      player.react.controlsNode.current.fullscreen.current.elementNode.current.addEventListener(
        'click',
        () => {
          // amp attaches this class to the element but doesn't remove it, which causes style issues.
          // Just remove this class altogether since full-screen mode delegates to the native player on iPad
          ampEl?.classList.remove('amp-full-screen');

          // We can assume that we want to go full screen since the icon is only available in the inline player on iPad
          ampEl?.requestFullscreen().catch(error => logError({ component: 'AkamaiPlayer', error }));
        }
      );
    }

    // TODO: see if we can avoid having to override this rule...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, videoContainerRef]);

  /**
   * Returns the interactive elements on the video element that trigger menus.
   *
   * I.e. clicking these buttons shows popups, and interaction with these cannot be determined as
   * being 'final' or not.
   *
   * Parking a mouse over these prevents the UI from hiding, and clicking on it triggers a UI hide
   * after a delay.
   */
  const getMenuButtons = useCallback(
    (...selectors: ControlButton[]) => {
      if (!player) return [];

      return Array.from(document.getElementById('amp')!.querySelectorAll(selectors.join(',')));
    },
    [player]
  );

  // Configure mouse hover event listeners on mount
  const mouseLeaveTimer = useRef<ReturnType<typeof setTimeout> | undefined>();
  const lastTouchTime = useRef<number | undefined>();

  useEffect(() => {
    if (!videoContainerRef) return;

    const onMouseMove = throttle(
      (event: MouseEvent) => {
        controlsDispatch({ type: 'changeHoverState', value: true });

        const target = event.target as HTMLElement;
        const menuControls = getMenuButtons(ControlButton.MUTE, ControlButton.CAPTIONS);
        const allControls = getMenuButtons(
          ControlButton.PLAY,
          ControlButton.PAUSE_OVERLAY,
          ControlButton.ASL,
          ControlButton.CAPTIONS,
          ControlButton.FULLSCREEN,
          ControlButton.MUTE,
          ControlButton.VOLUME
        );

        // Do nothing if this mouse move event is triggered by a touch event (like clicking).
        const isTouch = !!lastTouchTime.current && lastTouchTime.current + 1e3 > Date.now();

        const isOnControl = allControls.some(control => target.contains(control));
        const isMenuControl = menuControls.some(control => target.contains(control));
        const captionsPanelIsOpen = player?.react.settingsPanelNode.current.open;

        // If a menu is open with touch, keep the UI visible for 10 seconds.
        if (isTouch && isMenuControl) {
          clearTimeout(mouseLeaveTimer.current); // Clear the current timeout.

          mouseLeaveTimer.current = setTimeout(
            () => controlsDispatch({ type: 'changeHoverState', value: false }),
            10e3
          );

          return;
        }

        // If controls are open, keep UI visible.
        // If a mouse is hovered over any controls, keep UI visible.
        if (captionsPanelIsOpen || (!isTouch && isOnControl)) {
          clearTimeout(mouseLeaveTimer.current);
          return;
        }

        clearTimeout(mouseLeaveTimer.current);

        // Wait 4 seconds after the user stops moving the cursor to update hover to inactive
        mouseLeaveTimer.current = setTimeout(
          () => controlsDispatch({ type: 'changeHoverState', value: false }),
          4e3
        );
      },
      500,
      { leading: true, trailing: true }
    );

    const onButtonMouseEnter = () => {
      controlsDispatch({ type: 'changeHoverState', value: true });
    };

    const onButtonMouseLeave = () => {
      onMouseMove.cancel();

      if (player?.react.settingsPanelNode.current.open) {
        clearTimeout(mouseLeaveTimer.current);
        return;
      }
      controlsDispatch({ type: 'changeHoverState', value: false });
      clearTimeout(mouseLeaveTimer.current);
    };

    const onTouchStart = (_event: TouchEvent) => {
      lastTouchTime.current = Date.now();
    };

    videoContainerRef.addEventListener('mouseenter', onButtonMouseEnter);
    videoContainerRef.addEventListener('mouseleave', onButtonMouseLeave);
    videoContainerRef.addEventListener('mousemove', onMouseMove);
    videoContainerRef.addEventListener('touchstart', onTouchStart);

    return () => {
      videoContainerRef.removeEventListener('mouseenter', onButtonMouseEnter);
      videoContainerRef.removeEventListener('mouseleave', onButtonMouseLeave);
      videoContainerRef.removeEventListener('mousemove', onMouseMove);
      videoContainerRef.removeEventListener('touchstart', onTouchStart);

      clearTimeout(mouseLeaveTimer.current);
    };
  }, [videoContainerRef, controlsDispatch, player, getMenuButtons]);

  // Log User Interaction events
  useEffect(() => {
    if (!player) return;

    player.on<OnPlayStateChangeHandler>('playstatechange', ({ detail }) => {
      const previousState = detail.previous;
      const newState = detail.value;

      if (previousState === 'playing' && newState === 'paused') {
        logUserEvent({
          eventAction: UserAction.Pause,
          eventCategory: 'AkamaiPlayer',
          eventLabel: analyticsId,
        });
      }

      if (previousState === 'paused' && newState === 'playing') {
        logUserEvent({
          eventAction: UserAction.Play,
          eventCategory: 'AkamaiPlayer',
          eventLabel: analyticsId,
        });
      }

      // Other transition states are not user initiated (e.g. loading > playing, playing > ended)
    });

    player.on<OnFullScreenChangeHandler>('fullscreenchange', ({ detail: isFullscreen }) => {
      logUserEvent({
        eventAction: isFullscreen ? UserAction.Fullscreen : UserAction.ExitFullscreen,
        eventCategory: 'AkamaiPlayer',
        eventLabel: analyticsId,
      });
    });

    // Akamai fires this event many times as a user interacts with the volume bar, using debounce to log as a single interaction.
    player.on(
      'volumechange',
      debounce<OnVolumeChangeHandler>(({ detail: volume }) => {
        logUserEvent({
          eventAction: UserAction.SetVolume,
          eventCategory: 'AkamaiPlayer',
          eventLabel: `${analyticsId}:${Math.round(volume * 100)}`,
        });
      }, 500)
    );

    player.on<OnMuteChangeHandler>('mutechange', ({ detail: isMuted }) => {
      logUserEvent({
        eventAction: isMuted ? UserAction.Mute : UserAction.Unmute,
        eventCategory: 'AkamaiPlayer',
        eventLabel: analyticsId,
      });
    });

    // NOTE: uses `shouldLogUserEvents` to gate whether to log since this event can be triggered without user action
    player.on<OnSettingsChangeHandler>('settingschange', ({ detail }) => {
      // player is loading and is automatically configuring settings, not a user triggered event
      if (!shouldLogUserEvents.current) return;

      // change was not relevant to captions
      if (!detail.captions) return;

      if (!isUndefined(detail.captions?.visible)) {
        const captionsVisible = detail.captions.visible;

        logUserEvent({
          eventAction: captionsVisible ? UserAction.ShowCaptions : UserAction.HideCaptions,
          eventCategory: 'AkamaiPlayer',
          eventLabel: analyticsId,
        });
      }

      if (!isUndefined(detail.captions?.track)) {
        const activeTrack = detail.captions.track;

        logInfo({
          eventAction: 'CaptionsSelected',
          eventCategory: 'AkamaiPlayer',
          eventLabel: `${analyticsId}:${activeTrack.language}`,
        });
      }
    });

    // There is a bug in Akamai on desktop browsers which can cause native captions
    // to "flicker" on for a short while when the user switches between caption
    // languages. We can adjust the styling on these native captions to hide the
    // text to make the flickering less noticeable.
    if (isDesktop) {
      player.captioning?.changeSettings({
        fontOpacity: '0%',
        edgeOpacity: '0%',
      });
    }

    if (!isLiveEvent) {
      // Per discussion w/ Alex - if a user is repeately seeking during a short time span, they are trying
      // to find the right point in the video.  Using debounce here to avoid redundant logs.
      // NOTE: due to the 4 second debounce window, there is a substantial delay between user action and when the
      //       logevent is fired.
      player.on(
        'seeked',
        debounce<OnSeekedHandler>(() => {
          logUserEvent({
            eventAction: UserAction.Seek,
            eventCategory: 'AkamaiPlayer',
            eventLabel: analyticsId,
          });
        }, 4_000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  // Log Video Play timing events
  useEffect(() => {
    if (!player) return;

    // NOTE: Generates a heartbeat event (logs point in time in video every 1 second)
    //       so we can create a histogram of video views (identify most viewed content, etc.)
    let timeUpdateHandler = throttle<OnTimeUpdateHandler>(noop);
    const bucketDurationSeconds = 5;

    // If the stream contains timestamps, we use those values when logging the heartbeat event.
    // NOTE: if this is the case, we cannot use the value from the event hook itself as it reports
    //       the bufferOffset of the client.
    if (timestampBehavior === 'Use Manifest Timestamps') {
      timeUpdateHandler = throttle<OnTimeUpdateHandler>(({ detail: bufferOffset }) => {
        if (!isPlaying) return;

        const timestamp = player.toUTC(bufferOffset);
        const roundedToNearestSecond = Math.floor(timestamp / 1000);
        const bucketOffset = roundedToNearestSecond % bucketDurationSeconds;
        const bucketTimestamp = roundedToNearestSecond - bucketOffset;

        logValue({
          eventCategory: 'AkamaiPlayer',
          eventLabel: analyticsId,
          eventVariable: 'video_watch',
          // NOTE: value is the point in time in the video, not elapsed watch time.
          //       value is the UTC epoch value, truncated to second increments.
          eventValue: bucketTimestamp,
        });
      }, bucketDurationSeconds * 1000);
      // Otherwise, assume the player reporting relative timestamps from the event and report those.
    } else {
      timeUpdateHandler = throttle<OnTimeUpdateHandler>(({ detail: timestamp }) => {
        if (!isPlaying) return;

        let currentSecond = Math.floor(timestamp);

        if (timestampBehavior === 'Use Calculated Timestamps') {
          currentSecond += progressOffset;
        }

        const bucketOffset = currentSecond % bucketDurationSeconds;
        const bucketTimestamp = currentSecond - bucketOffset;

        logValue({
          eventCategory: 'AkamaiPlayer',
          eventLabel: analyticsId,
          eventVariable: 'video_watch',
          // NOTE: value is the point in time in the video, not elapsed watch time.
          eventValue: bucketTimestamp,
        });
      }, bucketDurationSeconds * 1000);
    }

    // reset listener whenever dependencies change
    player.on('timeupdate', timeUpdateHandler);

    return () => {
      player.off('timeupdate', timeUpdateHandler);
      timeUpdateHandler.cancel();
    };
  }, [player, timestampBehavior, progressOffset, isPlaying, analyticsId]);

  // Respond to user event - Toggle between asl and non-asl streams
  useEffect(() => {
    if (!player) return;
    if (!videoContainerRef) return;

    const useAslStream = showControls.isAslSelected && !!aslMedia;

    // We don't have a good way to differentiate the actively playing stream via the Akamai API
    // so using internal state instead.  NOTE: we are reliant on firing `setIsAslStreamPlaying()`
    // and `player?.setMedia()` synchronously.
    if (useAslStream === isAslStreamPlaying) return;

    setIsAslStreamPlaying(useAslStream);

    logUserEvent({
      eventAction: UserAction.Click,
      eventCategory: 'AkamaiPlayer',
      eventLabel: `${analyticsId}:${useAslStream ? 'ASL' : 'main'}`,
    });
    const newMedia = useAslStream ? aslMedia : media;
    player?.setMedia({ ...newMedia, startTime: player.currentTime });

    if (!Config.isDeploymentTypeProd) {
      videoContainerRef.setAttribute('data-akamai-media', useAslStream ? 'ASL' : 'main');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showControls.isAslSelected, player, videoContainerRef]);

  // Testing logic - render akamai settings as attributes on containing div
  useEffect(() => {
    if (!player) return;
    if (!videoContainerRef) return;
    if (Config.isDeploymentTypeProd) return;

    player.on<OnFullScreenChangeHandler>('fullscreenchange', ({ detail: isFullscreen }) => {
      videoContainerRef.setAttribute('data-akamai-screen', isFullscreen ? 'fullscreen' : 'inline');
    });

    player.on<OnSettingsChangeHandler>('settingschange', ({ detail }) => {
      // change was not relevant to captions
      if (!detail.captions) return;

      const captionsEnabledKey = 'data-akamai-captions';
      const captionsTrackKey = 'data-akamai-captions-track';

      if (!isUndefined(detail.captions?.visible)) {
        const captionsVisible = detail.captions.visible;

        videoContainerRef.setAttribute(captionsEnabledKey, captionsVisible.toString());

        if (!captionsVisible) {
          videoContainerRef.removeAttribute(captionsTrackKey);
        }
      }

      if (!isUndefined(detail.captions?.track)) {
        const activeTrack = detail.captions.track;
        videoContainerRef.setAttribute(captionsEnabledKey, 'true');
        videoContainerRef.setAttribute(captionsTrackKey, activeTrack.language);
      }
    });

    player.on<OnPlayStateChangeHandler>('playstatechange', ({ detail }) => {
      videoContainerRef.setAttribute('data-akamai-playstate', detail.value);
    });
  }, [player, videoContainerRef]);

  // Do not mistakenly fire user log events while Akamai setting are initializing.
  useEffect(() => {
    if (!player) return;
    if (!videoContainerRef) return;

    setTimeout(() => {
      shouldLogUserEvents.current = true;
    }, 300);
  }, [player, videoContainerRef]);

  return (
    <div
      data-testid="akamai-player-container"
      ref={measuredContainerRef}
      className={cx(ampPlayerCss, {
        [videoPlayerShowControlsCss]:
          showControls.isLoading || showControls.areControlsHovered || inInitialLoadInterval,
        [ampHideCcCss]: disableCaptions,
        [ampHideAslCss]: aslMedia === undefined,
        [ampAslActiveCss]: showControls.isAslSelected,
        // Firefox has a different video player layout due to its default Picture-in-Picture icon overlay
        [firefoxVideoPlayerCss]: isFirefox,
      })}
    >
      <div id="amp"></div>
    </div>
  );
};
