import { css } from '@emotion/css';
import { mobileMediaQuery, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';
import { Black, Gray, Green, White } from '@snapchat/snap-design-system-marketing/src/constants';

const controlSizePx = 32;
const volumeRangeWidthPx = 100;
const mediaControlBackground = 'rgba(0, 0, 0, 0.4)';
const mediaControlHoverBackground = 'rgba(0, 0, 0, 0.85)';
const borderRadiusPx = 10;

const enum ZIndex {
  CaptionSelector = 4,
  VolumeRange = 3,
  Controls = 2,
  Captions = 1,
}

export const volumeToggleClickableClassName = 'volume-toggle-clickable';
export const volumeToggleDisabledClassName = 'volume-toggle-disabled';
export const mainControlBarClassName = 'main-control-bar';
export const captionControlBarClassName = 'caption-control-bar';
export const captionClassName = 'caption';

export const eventPlayerWrapperCss = css`
  border-radius: ${borderRadiusPx}px;

  /** Wrapper should fill whatever is the parent container (while maintaining 16/9 aspect) */
  aspect-ratio: 16 / 9;
  width: 100%;

  /* Center overlays such as loading spinner */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const unloadedEventPlayerPosterCss = css`
  border-radius: ${borderRadiusPx}px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.8);
`;

export const unloadedEventPlayerPlayButtonCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
  width: 100px;
`;

export const eventPlayerLoadingSpinnerCss = css`
  ::before,
  ::after {
    border-color: white;
  }
`;

export const eventPlayerHlsCss = css`
  /* Setting a top level width here will ensure no matter resolution/size/height of given video stream, the player will
   always  */
  width: 100%;

  media-controller {
    /** Try to do as much styling as possible using media chrome variables */

    /* Background of each button */
    --media-control-background: ${mediaControlBackground};
    /* Hover background of each button */
    --media-control-hover-background: ${mediaControlHoverBackground};

    /** Player should fill up wrapper (while maintaining 16/9 aspect) */
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  /** In order to round player edges, we have to apply border radius to both controller and its associated video element
   * to avoid video element peaking out from behind the controller */
  video,
  media-controller {
    border-radius: ${borderRadiusPx}px;
  }

  /** Loading indicator should be visible whenever user is unable to play because not enough video has been buffered
    * yet. Toggle the opacity of the indicator depending on the "medialoading" attribute */
  media-controller[medialoading] {
    --media-loading-indicator-opacity: 1;
  }

  /** Hide loading indicator when media is paused but still able to be played ("mediaseekable" tells us the video can be played) */
  media-controller[mediapaused][mediaseekable] {
    --media-loading-indicator-opacity: 0;
  }

  /** Added a rounded border to all the controls and center them within the control bar */
  media-chrome-button,
  media-captions-button,
  media-fullscreen-button,
  media-mute-button,
  media-play-button {
    border-radius: ${controlSizePx / 2}px;
    align-self: center;
    padding: 5px;
    width: ${controlSizePx}px;
    height: ${controlSizePx}px;

    img {
      opacity: 0.75;
    }
  }

  /** Progress bar overrides */
  media-time-range {
    /* Color of progress bar */
    --media-range-track-color: rgba(17, 17, 17, 0.8);
    /* Color of buffered portion of progress bar */
    --media-time-range-buffered-color: rgba(17, 17, 17, 0.8);
    /* Color of elapsed portion of progress bar */
    --media-range-bar-color: #555;
    /* Color of progress bar knob */
    --media-range-thumb-background: #bfbfbf;
    /* Height of progress bar */
    --media-range-track-height: 7px;
    /** Border radius of progress bar */
    --media-range-track-border-radius: 10px;

    margin: 0px 60px;

    ${mobileMediaQuery} {
      margin: 0px 30px;
    }

    /** Unlike other controls, progress bar should not have a background color */
    background-color: transparent;
  }

  /** Hide bottom left play button on mobile and hide center play button on desktop */
  media-play-button {
    visibility: hidden;
  }
  ${mobileMediaQuery} {
    [slot='centered-chrome'] media-play-button {
      visibility: visible;
    }
  }
  ${nonMobileMediaQuery} {
    media-control-bar media-play-button {
      visibility: visible;
    }
  }

  /** Center play button style */
  [slot='centered-chrome'] > media-play-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;

    img {
      width: 60px;
      height: 60px;
    }
  }

  /** Hide center play button if media is loading */
  media-controller[medialoading] [slot='centered-chrome'] media-play-button {
    visibility: hidden;
  }

  .${mainControlBarClassName} {
    padding: 10px 17px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .${captionControlBarClassName} {
    /* Position below caption selector but above the rest of the player */
    z-index: ${ZIndex.Captions};
    width: 100%;
    display: flex;
    justify-content: center;

    /** Unlike other elements, captions should not hide when user is inactive */
    opacity: 1;
  }

  .${captionClassName} {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 15px 20px;
    font-size: 24px;
    line-height: 1.2;
    text-align: left;

    /** Keep caption box from reaching sides of the player in the case of longer
    * caption cues */
    margin: 0px 65px;

    ${mobileMediaQuery} {
      font-size: 10px;
      padding: 5px 7px;
    }
  }

  /** Move captions down if control bar is hidden */
  media-controller[userinactive]:not([mediapaused]) {
    .${captionControlBarClassName} {
      margin-bottom: -40px;
    }
  }
`;

/** Controls stack on bottom right */
export const cornerControlsCss = css`
  display: flex;
  /** column-reverse will make the stack in the upward direction */
  flex-direction: column-reverse;
  height: ${controlSizePx}px;
  width: ${controlSizePx}px;
  align-self: center;
  z-index: ${ZIndex.Controls};

  > * {
    margin: 9px 0px;
  }

  ${mobileMediaQuery} {
    > * {
      margin: 5px 0px;
    }
  }

  /* Whatever control is at the bottom should have no bottom margin */
  > *:first-child {
    margin-bottom: 0px;
  }

  /* Whatever control is at the top should have no top margin */
  > *:last-child {
    margin-top: 0px;
  }
`;

export const audioControlsCss = css`
  /* Before applying rotation, the volume control is a row consisting of a mute
  button and then a volume range */
  display: flex;
  flex-direction: row;

  height: ${controlSizePx}px;
  width: ${controlSizePx}px;
  z-index: ${ZIndex.VolumeRange}; /* Ensure volume control expands over other buttons */

  /** MediaChrome will treat the mute button and volume control as two separate controls with their
   * own backgrounds and borders. We want to override this behavior and treat the entire audio
   * control as one combined element */
  background-color: ${mediaControlBackground};
  border-radius: ${controlSizePx / 2}px;

  /** Rotate the audio control 90 degrees clockwise so it is vertical*/
  transform-origin: ${controlSizePx / 2}px ${controlSizePx / 2}px;
  rotate: 270deg;

  /** Rotate just the volume image back 90 degrees so it isn't sideways */
  img {
    rotate: 90deg;
  }

  media-volume-range {
    /** All pieces of the volume range should be white */
    --media-range-bar-color: white;
    --media-range-track-color: white;
    --media-range-track-background: white;
    --media-range-thumb-background: white;
    --media-range-track-height: 1px;
    --media-range-thumb-height: 20px;
    --media-range-thumb-width: 20px;

    width: ${volumeRangeWidthPx}px;
    height: ${controlSizePx}px;
    background-color: transparent;

    /** Volume range is hidden until volume button selected or hovered over */
    display: none;
    padding: 0px 15px;

    /** Prevents scrolling of page due to touching and dragging on volume range */
    touch-action: none;
  }

  media-mute-button {
    background-color: transparent;
  }

  /** The "clickable" mute button should not be displayed unless the controls have been hovered
   * over and thus the volume range is visible */
  .${volumeToggleClickableClassName} {
    display: none;
  }

  &:hover {
    background-color: ${mediaControlHoverBackground};
    width: ${controlSizePx + volumeRangeWidthPx}px;
    media-volume-range {
      display: block;
    }

    /** At this point, audio controls are hovered and volume range is visible which means the
     * mute button should actually mute the video now, so swap out the disabled mute button
     * with the clickable one */
    .${volumeToggleDisabledClassName} {
      display: none;
    }
    .${volumeToggleClickableClassName} {
      display: inline-flex;
    }
  }
`;

export const captionSelectorPanelCss = css`
  position: absolute;
  right: 60px;
  bottom: 20px;
  z-index: ${ZIndex.CaptionSelector}; /* Position above all other controls */
  display: block;
  max-height: 500px;
  background-color: ${White};
  color: ${Black.V50};
  width: 250px;
  padding: 14px;
  box-shadow: ${Gray.V150} 1px 1px 2px 2px;
  border-radius: 10px;
  font-size: 16px;
  text-align: left;
`;

export const captionSelectorItemCss = css`
  padding: 6px;
  cursor: pointer;

  .sds-icon {
    float: right;
    background-color: ${Green.V100};
    padding: 2px;
    border-radius: 50%;
  }
`;

export const captionSelectorItemActiveCss = css`
  font-weight: bold;
`;

export const captionSelectorDoneButtonCss = css`
  float: right;
  font-size: 12px;
`;

/**
 * Button wrappers are used to attach click listeners to MediaChrome buttons. This class matches the
 * border radius of the wrapper to that of the button to ensure the wrapper has no extra clickable
 * space around the button.
 */
export const buttonWrapperCss = css`
  border-radius: ${controlSizePx / 2}px;
`;

export const selectedControlCss = css`
  border: 1px solid #fffc00;
`;

export const topControlsCss = css`
  display: flex;
  flex-direction: row-reverse;
  padding: 10px;

  /* Never hide this control bar */
  opacity: 1;

  media-live-button {
    /* Live indicator should always be on (red) since we currently don't support rewinding of live stream */
    --media-live-button-icon-color: rgb(255, 0, 0);
    padding: 5px;
    border-radius: 5px;
  }

  /* On mobile, we move top controls to the left side, otherwise bottom right corner controls may cover up top controls
  on smaller screens  */
  ${mobileMediaQuery} {
    flex-direction: row;
  }
`;

export const centeredControlsCss = css`
  /** Here we use grid to stack center controls ontop of each other as described here:
  * https://css-tricks.com/how-to-stack-elements-in-css/ */
  display: grid;
  grid-template-rows: auto min-content;
  justify-items: center;

  * {
    grid-area: 1 / 1 / 1 / 1;
  }
`;

export const tooltipClassCss = css`
  media-text-display {
    --media-control-background: transparent;
    --media-control-hover-background: transparent;
    white-space: nowrap;
  }

  &.__react_component_tooltip {
    padding: 10px;
  }
`;

export const loadingIndicatorCss = css`
  /* Render loading indicator over the top of the player */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /** Keep loading indicator visible even when rest of chrome fades out due to inactivity */
  opacity: 1;
`;
